import React from "react"

import Layout from "@src/components/Layout"
import CommunityCallout from "@src/components/CommunityCallout"
import Callout from "@src/components/Callout"
import getAppUrl from "@src/utils/getAppUrl"
import track from "@src/utils/track"
import BigImage from "@src/components/BigImage"
import Section from "@src/components/Section"
import FeatureGrid from "@src/components/FeatureGrid"

import BeforeAfterBenefits from "@src/icons/screenshots/before-after-benefits.png"
import Sdk from "@src/icons/screenshots/sdk.png"
import OG from "@src/icons/og/OG_SDK.jpeg"

import * as styles from "./variation.module.scss"
const IndexPage = ({ location }) => {
  const appUrl = getAppUrl.hook(location)
  return (
    <Layout
      title="SDK that fits in your workflow."
      description="Focus on the frontend code while building a complete backend in low code, all of it without leaving your console."
      url="https://canonic.dev/features/sdk"
      location={location}
      ogImage={OG}
    >
      <Section
        blueGradient
        superscript="Frontend SDK"
        title="SDK that fits in your workflow."
        description="Focus on the frontend code while building a complete backend in low code, all of it without leaving your console."
        paddingBottom
        className={styles.centerTheHeadingSDK}
        hasBigImage
        buttons={[
          {
            title: "Start for free",
            isPrimary: true,
            href: appUrl,
            onClick: () => track("Start for free", location.pathname),
          },
        ]}
      />
      <BigImage src={Sdk} />
      <Section
        superscript="top features"
        title="A library to keep the frontend integration simple"
        withoutImage
        white
        noMargin
        paddingTop
        className={styles.centerTheHeadingSDKTopFeatures}
      />
      <FeatureGrid white>
        {[
          {
            title: "Modular Components",
            description:
              "Import components which are used by your project, keep your project light & fast.",
            link: {
              title: "Read more >",
              url: "/docs",
            },
          },
          {
            title: "Highly Customisable",
            description:
              "With a range of arguments, customize components to fit your brand design.",
            link: {
              title: "Read more >",
              url: "/docs",
            },
          },
          {
            title: "Automatic data fetching",
            description:
              "Take care of different states like loading, fetching, and refreshing data.",
            link: {
              title: "Read more >",
              url: "/docs",
            },
          },
          {
            title: "Filtering & Pagination",
            description:
              "Replicate the same code with built-in filtering and pagination in custom components.",
            link: {
              title: "Read more >",
              url: "/docs",
            },
          },
        ]}
      </FeatureGrid>
      <Section
        superscript="BENEFITS"
        title="Freedom to integrate everywhere in low code"
        description="With our SDK doing the heavy lifting of data management, focus on business logic that lets you deliver the best experience to users without leaving your editor."
        screenshot={BeforeAfterBenefits}
        black
        className={styles.sdkBenefits}
        buttons={[
          {
            title: "Start for free",
            isPrimary: true,
            href: appUrl,
            onClick: () => track("Start for free - Fold 3", location.pathname),
          },
        ]}
      />
      {/* <BigImage src={IntegrateEverywhere} /> */}

      <Callout location={location} title="sdk" />
      <CommunityCallout />
    </Layout>
  )
}

export default IndexPage
